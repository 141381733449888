import * as React from 'react'

const MODE_ICON_UNKNOWN = 'fa-circle-question';
const MODE_ICON_MAP = {
  'bicycle': 'fa-bicycle',
  'automobile': 'fa-car-side',
  'boat': 'fa-ship',
  'train': 'fa-train',
  'walk': 'fa-shoe-prints'
}

export default class TravelMetrics extends React.Component {
  renderTravelMetric(mode, distance, precision) {
    const iconClass = MODE_ICON_MAP[mode] ?? MODE_ICON_UNKNOWN;
    return (<span>{" " + distance.toFixed(precision) + " mi " }<i className={ `fas ${iconClass}` }></i></span>)
  }

  render() {
    const distances = this.props.distances ?? {}
    const precision = this.props.precision >= 0 ? this.props.precision : 1;
    const modes = Object.keys(MODE_ICON_MAP).filter(x => x in distances)
    return (
      <p className='has-text-grey'>
        { modes.map((mode) => this.renderTravelMetric(mode, distances[mode], precision))}
      </p>
    )
  }
}
